import React from "react"
import { navigate } from "gatsby"

import Layout from "../../layouts"

import { getActiveCodeItemList } from "../../api/item"

import "./sell-active-code.css"

class SellActiveCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItemList: [],
      visible: false,
    }
  }

  componentDidMount() {
    // 暂时不单独售卖激活码，直接开通vip，此处进行重定向
    navigate("/membership/vip")
    // this.init()
  }
  // init = async () => {
  //   const res = await getActiveCodeItemList()
  //   if (res.data.success) {
  //     this.setState({ activeItemList: res.data.data })
  //   }
  // }

  // handlePay = async (e, item) => {
  //   navigate(`/items?id=${item.itemId}&type=activeCode`)
  // }

  render() {
    return <></>
    // const { activeItemList } = this.state
    // return (
    //   <Layout isSpacerDisplay={true} title="激活码购买">
    //     <div className="mdnice-membership-container">
    //       <div className="mdnice-membership-table-container">
    //         <div className="mdnice-membership-sidebar">
    //           <div
    //             className="mdnice-membership-sidebar-row"
    //             onClick={() => navigate("/membership/vip")}
    //           >
    //             <p>产品功能</p>
    //           </div>
    //           <div
    //             className="mdnice-membership-sidebar-row-active"
    //             onClick={() => navigate("/membership/sell-active-code")}
    //           >
    //             <p>激活码购买</p>
    //           </div>
    //           <div
    //             className="mdnice-membership-sidebar-row"
    //             onClick={() => navigate("/membership/sell-coin")}
    //           >
    //             <p>墨点充值</p>
    //           </div>
    //         </div>

    //         <div className="mdnice-sell-active-code-item-container">
    //           {activeItemList.map(item => (
    //             <div
    //               className="mdnice-sell-active-code-coloum"
    //               key={item.name}
    //               onClick={(e) => this.handlePay(e, item)}
    //             >
    //               <div className="mdnice-sell-active-code-row">
    //                 <p className="mdnice-sell-active-code-name">{item.name}</p>
    //                 <p className="mdnice-sell-active-code-price">
    //                   {item.isVirtual ? item.price : <><span>¥</span>{item.price / 100}</>}
    //                 </p>
    //                 <p className="mdnice-sell-active-code-per-price ">
    //                   {item.isVirtual ? `墨点` : `¥${(item.price / 100 / item.duration).toFixed(2)}/天`}
    //                 </p>
    //                 <p className="mdnice-sell-active-code-device ">
    //                   支持 {item.deviceNum} 台设备
    //               </p>
    //               </div>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     </div>
    //   </Layout>
    // )
  }
}

export default SellActiveCode
